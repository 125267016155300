import React from "react"
import TitleComponent from '../components/titleComponent'
import Layout from '../layouts/mainLayout'
import { css } from '@emotion/core'
import { BlogContext } from '../context/mainContext'
import about from '../images/about.jpg'
import contact_call from '../images/contact_call.jpg'
import contact_mail from '../images/contact_mail.png'

class About extends React.Component {
  render() {
    const { theme } = this.props.context
    return (
      <Layout>
        <div css={containerStyle}>
            <div css={textContainer}>
                <TitleComponent title='WELCOME TO SR TRAVEL BOUTIQUE' />
                <p css={paragraphStyle(theme)}>SR Travel Boutique was incepted in 2020 with the vision of being a one-stop shop for all travel requirements– pan-India as well as abroad. We follow a B2C (business-to-customer) distribution channel and primarily focus on providing end-to-end travel solutions to the growing Indian diaspora. </p>
                <p css={paragraphStyle(theme)}>In regard to the services we offer: </p>
                <ul>
                    <li css={liStyle(theme)}>Domestic & international air tickets.</li>
                    <li css={liStyle(theme)}>Hotel accommodation.</li>
                    <li css={liStyle(theme)}>Tailored and custom-made domestic and international packages & tours.</li>
                    <li css={liStyle(theme)}>Passport related assistance.</li>
                    <li css={liStyle(theme)}>Visa documentation and appointment assistance.</li>
                    <li css={liStyle(theme)}>Overseas medical insurance.</li>
                    <li css={liStyle(theme)}>Foreign exchange</li>
                </ul>
                <h2 css={subheaderStyle(theme)}>Contact Us</h2>

                <ul>
                    <li css={liNoDotStyle}><img src={contact_call} alt="call" css={contact}/> <a href="tel:+1111111111">+91 89106 52294</a></li>
                    <li css={liNoDotStyle}><img src={contact_mail} alt="email" css={contact}/> <a href="mailto:srtravelboutiq@gmail.com">srtravelboutiq@gmail.com</a></li>
                </ul>
            </div>
            <div css={imageContainer}>
              <img
                  src={about}
                  css={imageStyle(theme)}
                  alt="about"
              />

                <p css={aboutFounder(theme)}>Mr. Soumendu Roy is an IATA certified travel agent and the Founder of SR Travel Boutique. With a robust experience of 20 years under his belt amalgamated with his love for travel and exploration, Soumendu strives to provide a stressfree travel experience for all his clients.
                    Providing exemplary services to the clientele, their cherished experiences and their feedback remain the topmost priority for Soumendu– and this shows in his two decades of service to the travel marketing industry of India.
                </p>
            </div>
        </div>
      </Layout>
    )
  }
}

export default function AboutWithContext(props) {
  return (
    <BlogContext.Consumer>
      {
        context => <About {...props} context={context} />
      }
    </BlogContext.Consumer>
  )
}

const containerStyle = css`
  @media (max-width: 700px) {
    width: 100%;
    padding: 0px 20px;
  }
`

const textContainer = css`
  width: 65%;
  float: left;
  padding-right: 20px;
`

const imageContainer = css`
  width: 35%;
  float: right;
`

const contact = css`
  height: 25px;
  width: 25px;
  margin-bottom: unset;
  background-color: ;
`

const liStyle = ({ primaryFontColor, fontFamily, highlight }) => css`
  color: ${primaryFontColor};
  font-family: ${fontFamily};
  margin-bottom: 0px;
  a {
    color: ${highlight};
  }
`

const liNoDotStyle = css`
  list-style-type: none;
`

const subheaderStyle = ({ fontFamily}) => css`
  font-family: ${fontFamily};
  font-weight: 300;
  margin-top: 20px;
`

const imageStyle = () => css`
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 60px -10px, rgba(0, 0, 0, 0.22) 0px 18px 36px -18px;
  max-width: 300px;
  margin-top: 30px;
  @media (max-width: 900px) {
    width: 100%;
  }
`

const paragraphStyle = ({ primaryFontColor, fontFamily, highlight }) => css`
  color: ${primaryFontColor};
  font-family: ${fontFamily};
  margin-bottom: 15px;
  a {
    color: ${highlight};
  }
`

const aboutFounder = ({ primaryFontColor, fontFamily, highlight }) => css`
  color: ${primaryFontColor};
  font-family: ${fontFamily};
  margin-bottom: 15px;
  a {
    color: ${highlight};
  }
`
